@import '_variables';

.txt-center{
    text-align: center !important;
}

.txt-title{
    font-size: 30px;
}
.txt-light{
    color: $light !important;
}
.txt-alert{
    color: $red !important;
}

.unselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently*/
}

.table-act-icon{
    font-size: 16px;
    color: $dark-grey !important;
}
.table-act-icon-active{
    font-size: 16px;
    color: $blue !important;
}

#clock-time{
    font-size: 20px;
    fill: black;
    fill-opacity: 0.85;
}
.txt-full{
    display: inline-block;
    width: 100%;
    height: 100%;
}

.body-cursor{
    cursor: crosshair !important;
}

.wifi-nodata{
    border: 2px solid grey;
    border-radius: 5px;
}
.wifi-alert{
    border: 2px solid red;
    border-radius: 5px;
}
.worker-noconnect{
    border: 2px solid red;
    border-radius: 5px;
}
.worker-search{
    border: 4px solid purple;
    border-radius: 5px;
}
