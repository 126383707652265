@import '_variables';

:root{
    --fontfamily: Roboto;
}

html, body{
    margin: 0px;
    padding: 0px;
    height: 100%;
}

body{
    margin: 0px;
    padding: 0px;
    height: 100%;
    color: rgba(0, 0, 0, 0.85);
    font-family: var(--fontfamily) !important;
    font-style: normal;
    background-color: $fon;
}

#root{
    margin: 0px;
    padding: 0px;
    height: 100%;
    background-color: $fon;
}

.login-tmpl{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-form{
        min-height: 260px;
        .input-form{
            height: 260px;
            width: 240px;
            margin: auto;
            p{
                margin: 0px;
                padding-top: 24px;
                button{
                    width: 100%;
                }
            }
            .remember-me{
                width: 100%;
                text-align: left !important;
            }
        }
    }
}

.div-flex-row{
    display: flex;
    flex-direction: row;
}

.container{
    height: calc(100% - 96px);
    margin: 0px 120px;
}

.modal-body{
    padding: 24px;
}

.appbar{
    text-transform: uppercase;
    height: 64px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #2f3d5d;
    align-items: center;
    padding: 0px 120px;
    justify-content: space-between;
    .appbar-icon{
        margin: 0px 30px 0px 0px;
    }
    .appbar-route{
        margin: 0px 30px 0px 30px;
        color: $light;
        size: 14px;
        font-weight: 400;
        cursor: pointer;
    }
    .appbar-route-active{
        color: $blue !important;
    }
    .appbar-user-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        p{
            margin: 0px;
            color: $light;
            padding: 0px 12px;
            cursor: pointer;
        }
        span{
            color: $light;
            padding: 12px;
            cursor: pointer;
        }
    }
}

.appbar-user{
    display: flex;
    flex-direction: row;
    align-items: center;
    p{
        margin: 0px;
        padding: 12px 6px !important;
    }
}

.page-title{
    margin: 8px 0px;
    p{
        margin-bottom: 8px;
    }
}

// Control div
#div-left-control{
    button{
        width: 144px;
        margin-right: 8px;
    }
    .ant-btn#control-delete-btn{
        color: $red;
        background: none !important;
        border: 0px !important;
        box-shadow: none;
    }
}

// geo plane
.div-geo-plane{
    position: relative;
    //margin-top: 16px;
    height: 100%;
    background-color: white;
    .leaflet-container{
        height: 100% !important;
        background-color: white;
    }
    #div-map-search{
        position: absolute;
        z-index: 1000;
        top: 16px;
        left: 16px;
    }

    #div-mine-select{
        position: absolute;
        z-index: 1000;
        top: 16px;
        right: 16px;
        div{
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.85);
        }
    }

    #div-map-select{
        position: absolute;
        z-index: 1000;
        top: 56px;
        right: 16px;
        div{
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.85);
        }
    }
}
.div-subconf{
    height: 100%;
    margin-top: 16px;
    max-width: 242px;
    width: 210px;
    #div-subconf-time{
        margin: 30px auto 0;
        width: 75%;
        //height: 340px;
        // background-color: $light;
        p{
            padding: 16px;
            margin-bottom: 0px;
            font-size: 16px;
            border-bottom: 1px solid $grey;
        }
        .subconf-clock{
            width: 100%;
            height: calc(100% - 54px);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .div-subconf-online{
        margin-top: 20px;
    }
}
.div-geo-container{
    height: 100%;
    flex: 1;
}
.margin-container-30{
    margin-right: 30px;
    margin-top: 16px;
}
.leaflet-control-attribution{
    display: none !important;
}

// settings
#settings-conf{
    flex-wrap: nowrap;
    flex: 1;
    p{
        margin-right: 32px;
        cursor: pointer;
        @extend .unselect;
        padding-bottom: 12px;
    }
    .active{
        color: $blue !important;
        border-bottom: 2px solid $blue;
    }
}
#settings-content{
    margin-top: 24px;
    width: 100%;
    height: calc(100% - 130px);
    position: relative;
}
.settings-title{
    background-color: $light;
    p{
        padding: 16px;
        font-size: 16px;
        border-bottom: 1px solid $grey;
        margin-bottom: 0px;
        font-weight: bold;
    }
}
.settings-body{
    background-color: $light;
    height: calc(100% - 100px);
    overflow-y: auto;
}
.settings-btn{
    margin-top: 12px;
}

// props block
.div-props{
    background-color: $light;
    width: 100%;
    .props-title{
        font-size: 16px;
        margin-bottom: 0px;
        padding: 16px 24px;
        border-bottom: 1px solid $grey;
    }
}
.icon-row{
    cursor: pointer;
    border-bottom: 1px solid $grey;
    .icon-name{
        width: 70%;
        padding: 12px 24px;
    }
    padding: 16px;
    .icon-position{
        width: 30%;
        text-align: left;
        svg{
            width: 24px;
            height: 24px;
        }
    }
    .icon-action{
        color: $light;
        padding-right: 24px;
    }
}
.icon-row:hover{
    td{
        background-color: $light-blue;
    }
    .icon-action{
        color: $blue;
    }
}

.front-icon{
    z-index: 2200 !important;
}

// new horizont
.dlg-img-body{
    padding: 24px;
    .img-active{
        height: 160px;
        border: 1px dashed #91D5FF;
        background-color: #E6F7FF; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.play-control{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.dropdown-div-menu{
    position: absolute;
    z-index: 10000;
    background-color: white;
    text-align: left;
}
.dropdown-div-menu-horizon-wrap{
    position: absolute;
    right: 0;
    height: 350px;
}
.dropdown-div-menu-horizon-wrap button{
    position: absolute;
    bottom: 0;
    right: 0;
}
.dropdown-div-menu-horizon{
    display: flex;
    right: 0;
    height: 300px;
    overflow-y: auto;
}
.dropdown-div-menu-horizon .mine-item{
    min-width: 126px;
    margin-right: 15px;
}
.scheme-workers-table{
    margin-top: 20px;
}
.hidden{
    display: none!important;
}
.div-loader-visual{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: gray;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    z-index: 1000;
}
.div-reports-container{
    position: relative;
}
.informers_date{
    height: 35%;
}
.horizons_informer{
    height: 30%;
}
.informers_circle{
    height: 300px;
}
.dash_info_wrap{
    width: 170px;
    height: 180px
}
#root{
    min-width: 1400px;
}
.scheme-tab{
    cursor: pointer;
    margin-right: 4px;
    padding: 2px 8px;
    background-color: gray;
}
.scheme-tab.active{
    background-color: white;
}
.searchBtn{
    padding: 2px 8px;
    background-color: #2f3d5d;
    color: white;
    cursor: pointer;
}

.font-size-8{
    font-size: 8px;
}
.font-size-10{
    font-size: 10px;
}
.font-size-12{
    font-size: 12px;
}
.font-size-14{
    font-size: 14px;
}
.font-size-16{
    font-size: 16px;
}
.font-size-18{
    font-size: 18px;
}
.font-size-20{
    font-size: 20px;
}
.font-size-22{
    font-size: 22px;
}
.font-size-24{
    font-size: 24px;
}
.font-size-26{
    font-size: 26px;
}
.font-size-28{
    font-size: 28px;
}
.font-size-30{
    font-size: 30px;
}

@media screen and (max-width: 1500px){
    .container{
        height: calc(100% - 96px);
        margin: 0px 2px;
    }
}
@media screen and (max-height: 820px){
    .informers_date{
        height: 20%;
    }
    .horizons_informer{
        height: 20%;
    }
    .informers_circle{
        height: 200px;
    }
}