// cutomizatoin
.ant-modal-body{
    padding: 0px !important;
}
.ant-modal-confirm-body-wrapper{
    padding: 24px !important;
}
.ant-picker-input input:disabled{
    color: black!important;
}
.ant-switch-checked{
    background-color: #2f3d5d!important;
}
.ant-switch-handle::before{
    background-color: gray!important;
}
.ant-btn-primary{
    background-color: #2f3d5d!important;
    border-color: #2f3d5d!important;
}
.g2-tooltip{
    display: none;
}
.leaflet-div-icon{
    border: 0px!important;
    background: none;
}